import React, { Component } from 'react';

import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { translate } from 'react-i18next';

import UIStore from '../../Stores/UIStore';
import mapStore from '../../Stores/MapStore';
import reservationStore from '../../Stores/ReservationStore';
import OptionsStore from '../../Stores/OptionsStore';

import { Col, Row } from 'reactstrap';

const south = {
  "type": "Feature",
  "geometry": {
    "type": "Polygon",
    "coordinates": [[
      [
        37.76751995086671,
        44.66834766432113
      ],
      [
        37.76803493499757,
        44.66853078928521
      ],
      [
        37.76816368103028,
        44.67020940783032
      ],
      [
        37.76636123657227,
        44.6722542047393
      ],
      [
        37.76546001434326,
        44.67228472354974
      ],
      [
        37.7646017074585,
        44.6713386329532
      ],
      [
        37.76284217834473,
        44.67069772409691
      ],
      [
        37.7587652206421,
        44.67234576112239
      ],
      [
        37.75782108306886,
        44.67237627988462
      ],
      [
        37.75859355926514,
        44.6750008333139
      ],
      [
        37.75752067565919,
        44.6811345004573
      ],
      [
        37.75833606719971,
        44.68107347214158
      ],
      [
        37.75833606719971,
        44.68183632146762
      ],
      [
        37.76485919952393,
        44.68168375240597
      ],
      [
        37.76159763336182,
        44.68668780809289
      ],
      [
        37.75915145874024,
        44.68699291945778
      ],
      [
        37.75962352752686,
        44.69007445415632
      ],
      [
        37.76151180267335,
        44.69285074601181
      ],
      [
        37.76533126831055,
        44.69166092294134
      ],
      [
        37.76799201965333,
        44.69178295720174
      ],
      [
        37.77996540069581,
        44.69318633271469
      ],
      [
        37.7871322631836,
        44.6943151100804
      ],
      [
        37.78730392456055,
        44.695779437121
      ],
      [
        37.79090881347657,
        44.69657259880751
      ],
      [
        37.79202461242677,
        44.69471170231011
      ],
      [
        37.79798984527589,
        44.69214905844017
      ],
      [
        37.79640197753907,
        44.69053209384251
      ],
      [
        37.80344009399415,
        44.68507069102827
      ],
      [
        37.80661582946778,
        44.68122604281035
      ],
      [
        37.80901908874512,
        44.68043267094536
      ],
      [
        37.80944824218751,
        44.67860177133203
      ],
      [
        37.80884742736817,
        44.67768629982934
      ],
      [
        37.80447006225587,
        44.67768629982934
      ],
      [
        37.80000686645508,
        44.6756111775412
      ],
      [
        37.79949188232422,
        44.67469565878957
      ],
      [
        37.79966354370117,
        44.67384116157171
      ],
      [
        37.80060768127442,
        44.67262042940384
      ],
      [
        37.80086517333984,
        44.6722542047393
      ],
      [
        37.801251411438,
        44.67222368591278
      ],
      [
        37.8010368347168,
        44.67210161044606
      ],
      [
        37.80172348022462,
        44.67170486340331
      ],
      [
        37.80219554901124,
        44.6720710915392
      ],
      [
        37.80369758605958,
        44.67188797776053
      ],
      [
        37.80421257019044,
        44.67088084163618
      ],
      [
        37.8015947341919,
        44.6703009674437
      ],
      [
        37.80086517333984,
        44.66996524815421
      ],
      [
        37.80009269714356,
        44.66929380374146
      ],
      [
        37.79914855957032,
        44.66782880711428
      ],
      [
        37.79833316802979,
        44.66709629491535
      ],
      [
        37.79717445373536,
        44.66660794830666
      ],
      [
        37.79670238494874,
        44.66596698713919
      ],
      [
        37.79434204101563,
        44.66447138351995
      ],
      [
        37.79253959655762,
        44.66367778225687
      ],
      [
        37.79073715209962,
        44.66343359506718
      ],
      [
        37.79030799865723,
        44.66340307159614
      ],
      [
        37.78906345367432,
        44.66297574131428
      ],
      [
        37.78799057006837,
        44.6628231225931
      ],
      [
        37.78524398803712,
        44.6628231225931
      ],
      [
        37.78434276580811,
        44.66276207499211
      ],
      [
        37.78369903564454,
        44.66248735999223
      ],
      [
        37.78326988220216,
        44.6620905471387
      ],
      [
        37.7816390991211,
        44.6611748148032
      ],
      [
        37.78082370758057,
        44.66090009228211
      ],
      [
        37.77837753295898,
        44.66053379356238
      ],
      [
        37.77687549591065,
        44.65970961298158
      ],
      [
        37.77288436889649,
        44.65867173928834
      ],
      [
        37.77185440063477,
        44.65980118918023
      ],
      [
        37.77202606201173,
        44.66047274355076
      ],
      [
        37.77022361755372,
        44.66334202460587
      ],
      [
        37.76979446411134,
        44.66309783600193
      ],
      [
        37.76962280273438,
        44.66349464196102
      ],
      [
        37.769193649292,
        44.66428824573119
      ],
      [
        37.76885032653809,
        44.66462399790199
      ],
      [
        37.7686357498169,
        44.66537180256619
      ],
      [
        37.76803493499757,
        44.66651638285953
      ],
      [
        37.76812076568604,
        44.66663847009024
      ],
      [
        37.76794910430909,
        44.66737098807476
      ],
      [
        37.76773452758789,
        44.6679508915808
      ],
      [
        37.76773452758789,
        44.6679508915808
      ]
    ]]
  },
  "properties": {
    "code": "south",
    "name": "Зона № Южный район",
    "address": "",
    "category": "Уличная парковка",
    "description": "",
    "objecttype": "zones",
    "linkpicture": null
  }
};
const seaside = {
  "type": "Feature",
  "geometry": {
    "type": "Polygon",
    "coordinates": [[
      [
        37.72039890289307,
        44.69921130852961
      ],
      [
        37.71567821502686,
        44.69951635392232
      ],
      [
        37.71505594253541,
        44.69959261501939
      ],
      [
        37.71505594253541,
        44.70142285121541
      ],
      [
        37.71550655364991,
        44.70258197088159
      ],
      [
        37.71503448486328,
        44.70547966851703
      ],
      [
        37.71353244781495,
        44.70557117239567
      ],
      [
        37.71288871765137,
        44.70639470079463
      ],
      [
        37.71409034729005,
        44.70791972244812
      ],
      [
        37.7131462097168,
        44.70917021022306
      ],
      [
        37.71383285522462,
        44.70996318847249
      ],
      [
        37.71477699279786,
        44.70950570234677
      ],
      [
        37.716064453125,
        44.71005468526371
      ],
      [
        37.71687984466553,
        44.70941420468772
      ],
      [
        37.71786689758302,
        44.7073707192814
      ],
      [
        37.71855354309083,
        44.70895671422275
      ],
      [
        37.72202968597413,
        44.71157961048961
      ],
      [
        37.72546291351319,
        44.71121363209965
      ],
      [
        37.72846698760986,
        44.71209807591383
      ],
      [
        37.72893905639649,
        44.7136229473009
      ],
      [
        37.7327585220337,
        44.71685554176403
      ],
      [
        37.73730754852296,
        44.71895968075193
      ],
      [
        37.73962497711182,
        44.71966104341111
      ],
      [
        37.7385950088501,
        44.72051486473866
      ],
      [
        37.74044036865235,
        44.72182606581594
      ],
      [
        37.74018287658692,
        44.72280182407619
      ],
      [
        37.73773670196534,
        44.72069782481243
      ],
      [
        37.73636341094971,
        44.7204233844848
      ],
      [
        37.73494720458985,
        44.7210942363208
      ],
      [
        37.73786544799805,
        44.72286280842103
      ],
      [
        37.737135887146,
        44.72322871314009
      ],
      [
        37.73619174957276,
        44.72277133187967
      ],
      [
        37.73473262786865,
        44.72274083966708
      ],
      [
        37.73400306701661,
        44.72249690138771
      ],
      [
        37.73310184478761,
        44.72264936293285
      ],
      [
        37.73241519927979,
        44.7231372371773
      ],
      [
        37.73159980773926,
        44.72322871314009
      ],
      [
        37.72941112518311,
        44.7229237927016
      ],
      [
        37.72653579711914,
        44.72319822116856
      ],
      [
        37.72460460662843,
        44.72429592201866
      ],
      [
        37.72670745849609,
        44.72435690478849
      ],
      [
        37.727952003479,
        44.72493623789581
      ],
      [
        37.72915363311768,
        44.72676567277721
      ],
      [
        37.7278232574463,
        44.72795477442435
      ],
      [
        37.72078514099121,
        44.73228411747805
      ],
      [
        37.70739555358887,
        44.7223444394408
      ],
      [
        37.69975662231446,
        44.72423493918451
      ],
      [
        37.69589424133302,
        44.72399100720512
      ],
      [
        37.69306182861329,
        44.72460083522505
      ],
      [
        37.67546653747559,
        44.73697895420884
      ],
      [
        37.67812728881837,
        44.73953961359393
      ],
      [
        37.681303024292,
        44.7406979698717
      ],
      [
        37.68078804016114,
        44.74411193758502
      ],
      [
        37.6732349395752,
        44.75252406891944
      ],
      [
        37.6662826538086,
        44.75916756813842
      ],
      [
        37.6908302307129,
        44.77586436732678
      ],
      [
        37.6966667175293,
        44.77123362204492
      ],
      [
        37.70130157470704,
        44.76818687659432
      ],
      [
        37.70885467529298,
        44.76721188410699
      ],
      [
        37.71142959594727,
        44.76964933446794
      ],
      [
        37.7021598815918,
        44.77586436732678
      ],
      [
        37.70679473876954,
        44.77891070777316
      ],
      [
        37.70971298217774,
        44.77708292279128
      ],
      [
        37.71400451660157,
        44.77537693794092
      ],
      [
        37.71966934204102,
        44.77135548852007
      ],
      [
        37.72172927856446,
        44.76903998152066
      ],
      [
        37.72104263305665,
        44.76355551570674
      ],
      [
        37.71966934204102,
        44.76258044504729
      ],
      [
        37.72224426269532,
        44.76221478930713
      ],
      [
        37.72619247436523,
        44.76130063983164
      ],
      [
        37.72953987121583,
        44.76075214320344
      ],
      [
        37.73391723632813,
        44.75880189079811
      ],
      [
        37.73443222045899,
        44.75794863467065
      ],
      [
        37.73357391357423,
        44.75484023793785
      ],
      [
        37.73709297180177,
        44.75191453532806
      ],
      [
        37.73846626281739,
        44.75051258367135
      ],
      [
        37.73666381835938,
        44.74819624120938
      ],
      [
        37.73657798767091,
        44.74728186994707
      ],
      [
        37.76275634765626,
        44.73155242028241
      ],
      [
        37.76290655136109,
        44.73139998220147
      ],
      [
        37.76174783706666,
        44.72920482928607
      ],
      [
        37.76556730270386,
        44.72751267538458
      ],
      [
        37.76185512542725,
        44.72264936293285
      ],
      [
        37.76297092437745,
        44.72197852912891
      ],
      [
        37.76041746139527,
        44.71968391378947
      ],
      [
        37.7579391002655,
        44.71863949062445
      ],
      [
        37.75646924972535,
        44.71706900862709
      ],
      [
        37.75580406188966,
        44.71641335789971
      ],
      [
        37.75549292564393,
        44.71608552975017
      ],
      [
        37.75505304336549,
        44.71570433189208
      ],
      [
        37.75477409362794,
        44.71539937179757
      ],
      [
        37.75396943092347,
        44.71467508513208
      ],
      [
        37.75337934494019,
        44.71452260257368
      ],
      [
        37.7530574798584,
        44.71430912631693
      ],
      [
        37.75288045406342,
        44.71425194504303
      ],
      [
        37.75246739387513,
        44.71416807907242
      ],
      [
        37.75173783302308,
        44.71394316518754
      ],
      [
        37.75071859359742,
        44.71363057155689
      ],
      [
        37.74955451488496,
        44.71318455089224
      ],
      [
        37.74846553802491,
        44.71279952174202
      ],
      [
        37.74619102478028,
        44.71159485953896
      ],
      [
        37.74325132369996,
        44.70999368741897
      ],
      [
        37.74123430252076,
        44.70996318847249
      ],
      [
        37.73554801940919,
        44.70929220758396
      ],
      [
        37.73271560668946,
        44.70729446842973
      ],
      [
        37.72707223892212,
        44.70572367853865
      ],
      [
        37.72049546241761,
        44.69922656083742
      ]
    ]]
  },
  "properties": {
    "code": "seaside",
    "name": "Зона № Приморский район",
    "address": "",
    "category": "Уличная парковка",
    "description": "",
    "objecttype": "zones",
    "linkpicture": null
  }
};
const east = {
  "type": "Feature",
  "geometry": {
    "type": "Polygon",
    "coordinates": [[
      [
        37.77992248535156,
        44.7256375281054
      ],
      [
        37.78069496154786,
        44.72627782913262
      ],
      [
        37.78189659118653,
        44.72725351230753
      ],
      [
        37.78563022613526,
        44.72822917902534
      ],
      [
        37.78738975524903,
        44.72920482928607
      ],
      [
        37.78957843780518,
        44.72844260392613
      ],
      [
        37.79241085052491,
        44.72944873927983
      ],
      [
        37.79614448547364,
        44.72899140719779
      ],
      [
        37.80082225799561,
        44.72877798432199
      ],
      [
        37.80279636383057,
        44.72819868968954
      ],
      [
        37.80532836914063,
        44.72627782913262
      ],
      [
        37.80923366546632,
        44.72115522246563
      ],
      [
        37.81339645385743,
        44.71978301865789
      ],
      [
        37.81871795654298,
        44.72231394700319
      ],
      [
        37.82373905181885,
        44.72118571551391
      ],
      [
        37.82931804656982,
        44.71871572652981
      ],
      [
        37.83176422119141,
        44.71719098933812
      ],
      [
        37.83322334289552,
        44.71584918737395
      ],
      [
        37.83777236938477,
        44.71365344431882
      ],
      [
        37.84322261810303,
        44.71118313379603
      ],
      [
        37.84343719482422,
        44.70974969539716
      ],
      [
        37.84399509429932,
        44.70913971084265
      ],
      [
        37.84485340118408,
        44.70895671422275
      ],
      [
        37.84489631652833,
        44.70642520162145
      ],
      [
        37.84772872924805,
        44.70523565746684
      ],
      [
        37.84905910491944,
        44.70538816449373
      ],
      [
        37.85060405731201,
        44.70468662884321
      ],
      [
        37.85120487213136,
        44.70346654659258
      ],
      [
        37.85300731658936,
        44.70215492949401
      ],
      [
        37.8533935546875,
        44.70127033374219
      ],
      [
        37.85541057586671,
        44.70029421239948
      ],
      [
        37.85691261291505,
        44.69925706544099
      ],
      [
        37.857985496521,
        44.69864697031633
      ],
      [
        37.85850048065186,
        44.69672512865554
      ],
      [
        37.86103248596192,
        44.69535234555911
      ],
      [
        37.86154747009278,
        44.69437612444637
      ],
      [
        37.86403656005859,
        44.69358293267462
      ],
      [
        37.86468029022218,
        44.69315582491296
      ],
      [
        37.86571025848389,
        44.69123380100492
      ],
      [
        37.86935806274415,
        44.69321684050034
      ],
      [
        37.8700876235962,
        44.69642006855768
      ],
      [
        37.86948680877686,
        44.69773181557595
      ],
      [
        37.86824226379395,
        44.69809787920781
      ],
      [
        37.86734104156494,
        44.69760979385106
      ],
      [
        37.86682605743408,
        44.69678614048226
      ],
      [
        37.86643981933595,
        44.69605399431886
      ],
      [
        37.86493778228761,
        44.6953828522037
      ],
      [
        37.86360740661622,
        44.69519981209504
      ],
      [
        37.8634786605835,
        44.69541335883224
      ],
      [
        37.86382198333741,
        44.69654209278969
      ],
      [
        37.86450862884522,
        44.69800636351681
      ],
      [
        37.8651523590088,
        44.69879949470017
      ],
      [
        37.86523818969727,
        44.69928757002845
      ],
      [
        37.86485195159913,
        44.69962311943009
      ],
      [
        37.86416530609132,
        44.69962311943009
      ],
      [
        37.86339282989503,
        44.69901302816255
      ],
      [
        37.86253452301026,
        44.69855545549317
      ],
      [
        37.86176204681397,
        44.69821989990413
      ],
      [
        37.86038875579835,
        44.69806737399355
      ],
      [
        37.86060333251954,
        44.69886050434121
      ],
      [
        37.86081790924073,
        44.69940958821768
      ],
      [
        37.86210536956787,
        44.70081277891188
      ],
      [
        37.8625774383545,
        44.70148385809218
      ],
      [
        37.86287784576417,
        44.70249046227799
      ],
      [
        37.86270618438721,
        44.70294800384954
      ],
      [
        37.86193370819092,
        44.70307001432457
      ],
      [
        37.86150455474854,
        44.70294800384954
      ],
      [
        37.86107540130615,
        44.70270398212803
      ],
      [
        37.86021709442139,
        44.70242945646191
      ],
      [
        37.85991668701173,
        44.70200241394934
      ],
      [
        37.8591012954712,
        44.70172788495647
      ],
      [
        37.85755634307862,
        44.70151436150648
      ],
      [
        37.85609722137452,
        44.7016058716529
      ],
      [
        37.8559684753418,
        44.70197191079222
      ],
      [
        37.85622596740723,
        44.70300900911919
      ],
      [
        37.85717010498048,
        44.70438161069124
      ],
      [
        37.857985496521,
        44.70505264850421
      ],
      [
        37.85837173461915,
        44.70615069360154
      ],
      [
        37.85789966583253,
        44.70660820624484
      ],
      [
        37.85717010498048,
        44.70660820624484
      ],
      [
        37.85631179809571,
        44.70624219641947
      ],
      [
        37.85541057586671,
        44.7053576631205
      ],
      [
        37.85455226898194,
        44.70493064220767
      ],
      [
        37.85330772399903,
        44.70447311630558
      ],
      [
        37.85270690917969,
        44.70425960298046
      ],
      [
        37.8521490097046,
        44.70444261445022
      ],
      [
        37.85232067108155,
        44.7053576631205
      ],
      [
        37.85219192504884,
        44.70618119455693
      ],
      [
        37.85180568695069,
        44.70648620322687
      ],
      [
        37.85099029541016,
        44.70645570243219
      ],
      [
        37.84983158111573,
        44.70615069360154
      ],
      [
        37.84918785095216,
        44.70703521478281
      ],
      [
        37.84815788269043,
        44.70773672197112
      ],
      [
        37.84712791442872,
        44.70846872040779
      ],
      [
        37.85974502563477,
        44.71758742487312
      ],
      [
        37.86026000976563,
        44.72039289103473
      ],
      [
        37.86043167114259,
        44.72295428481777
      ],
      [
        37.85940170288087,
        44.7231372371773
      ],
      [
        37.85725593566895,
        44.72240542426776
      ],
      [
        37.85579681396485,
        44.72063683818546
      ],
      [
        37.85081863403321,
        44.7196000556913
      ],
      [
        37.84953117370606,
        44.718075341803
      ],
      [
        37.84575462341309,
        44.71929511612791
      ],
      [
        37.84275054931641,
        44.72277133187967
      ],
      [
        37.84575462341309,
        44.72557654668552
      ],
      [
        37.8472137451172,
        44.72832064693639
      ],
      [
        37.84910202026368,
        44.73027192791504
      ],
      [
        37.85150527954102,
        44.73490595641142
      ],
      [
        37.8417205810547,
        44.74100279661397
      ],
      [
        37.83639907836915,
        44.74136858658327
      ],
      [
        37.83433914184571,
        44.74600172587976
      ],
      [
        37.82506942749024,
        44.74795241028706
      ],
      [
        37.82043457031251,
        44.75343835725288
      ],
      [
        37.8087615966797,
        44.75636398271945
      ],
      [
        37.80395507812501,
        44.75685157256379
      ],
      [
        37.8010368347168,
        44.75880189079811
      ],
      [
        37.79245376586915,
        44.76014269639824
      ],
      [
        37.79159545898438,
        44.762336674811
      ],
      [
        37.7867889404297,
        44.76477433088797
      ],
      [
        37.78215408325196,
        44.77086802107666
      ],
      [
        37.7703094482422,
        44.77172108640259
      ],
      [
        37.76773452758789,
        44.76782125634016
      ],
      [
        37.7648162841797,
        44.76026458627357
      ],
      [
        37.76241302490235,
        44.7597770252294
      ],
      [
        37.75640487670899,
        44.7609959201255
      ],
      [
        37.75297164916993,
        44.76684625768132
      ],
      [
        37.74936676025391,
        44.76623687516234
      ],
      [
        37.73447513580323,
        44.75788768732225
      ],
      [
        37.73370265960693,
        44.75474881197326
      ],
      [
        37.73730754852296,
        44.75179262783834
      ],
      [
        37.73850917816163,
        44.75054306124288
      ],
      [
        37.73670673370362,
        44.74819624120938
      ],
      [
        37.73657798767091,
        44.74734282848123
      ],
      [
        37.76282072067261,
        44.7315676640684
      ],
      [
        37.76292800903321,
        44.73139998220147
      ],
      [
        37.76528835296631,
        44.73304629221313
      ],
      [
        37.77966499328614,
        44.72585096256864
      ],
      [
        37.77966499328614,
        44.72585096256864
      ]
    ]]
  },
  "properties": {
    "code": "east",
    "name": "Зона № Восточный район",
    "address": "",
    "category": "Уличная парковка",
    "description": "",
    "objecttype": "zones",
    "linkpicture": null
  }
};
const center = {
  "type": "Feature",
  "geometry": {
    "type": "Polygon",
    "coordinates": [[
      [
        37.75902271270753,
        44.68702343050587
      ],
      [
        37.75507450103761,
        44.68717598550529
      ],
      [
        37.75554656982423,
        44.68845743163819
      ],
      [
        37.75172710418702,
        44.69126430981911
      ],
      [
        37.75443077087403,
        44.69208804172781
      ],
      [
        37.75670528411866,
        44.68946426228315
      ],
      [
        37.75855064392091,
        44.69196600811024
      ],
      [
        37.75726318359375,
        44.69245414103768
      ],
      [
        37.75623321533204,
        44.6929422698509
      ],
      [
        37.75610446929932,
        44.69318633271469
      ],
      [
        37.75691986083985,
        44.69425409565017
      ],
      [
        37.75498867034912,
        44.69498626457038
      ],
      [
        37.75477409362794,
        44.69526082552888
      ],
      [
        37.75520324707032,
        44.69590146270299
      ],
      [
        37.75455951690675,
        44.69605399431886
      ],
      [
        37.75447368621826,
        44.69626753790609
      ],
      [
        37.75524616241456,
        44.69776232096699
      ],
      [
        37.75378704071046,
        44.6981588895881
      ],
      [
        37.75052547454835,
        44.69745726633335
      ],
      [
        37.74533271789552,
        44.69852495052
      ],
      [
        37.7446460723877,
        44.69889100913762
      ],
      [
        37.74142742156983,
        44.69956211059261
      ],
      [
        37.74065494537354,
        44.69956211059261
      ],
      [
        37.74065494537354,
        44.69779282634199
      ],
      [
        37.73868083953858,
        44.69849444553072
      ],
      [
        37.73670673370362,
        44.69599298172071
      ],
      [
        37.73524761199952,
        44.69623703172753
      ],
      [
        37.73387432098389,
        44.69663361079493
      ],
      [
        37.73305892944337,
        44.69709119865158
      ],
      [
        37.73258686065675,
        44.69904353287858
      ],
      [
        37.73310184478761,
        44.69910454226248
      ],
      [
        37.73387432098389,
        44.69977564124254
      ],
      [
        37.73310184478761,
        44.70041622846732
      ],
      [
        37.73245811462403,
        44.70001967530671
      ],
      [
        37.73262977600098,
        44.70169738165467
      ],
      [
        37.72052764892579,
        44.69922656083742
      ],
      [
        37.72709369659425,
        44.70572367853865
      ],
      [
        37.73271560668946,
        44.70727921824734
      ],
      [
        37.73554801940919,
        44.70926170826785
      ],
      [
        37.74134159088136,
        44.70996318847249
      ],
      [
        37.74322986602784,
        44.70999368741897
      ],
      [
        37.74837970733643,
        44.71273852679008
      ],
      [
        37.75095462799073,
        44.71371443830646
      ],
      [
        37.75262832641602,
        44.71420238789327
      ],
      [
        37.75297164916993,
        44.71426338130233
      ],
      [
        37.75340080261231,
        44.71450735429573
      ],
      [
        37.75395870208741,
        44.71465983689431
      ],
      [
        37.75554656982423,
        44.71609315368172
      ],
      [
        37.75722026824952,
        44.71789237343644
      ],
      [
        37.75799274444581,
        44.71865473781356
      ],
      [
        37.76035308837891,
        44.71966104341111
      ],
      [
        37.76155471801759,
        44.72054535812446
      ],
      [
        37.76301383972168,
        44.72197852912891
      ],
      [
        37.76189804077149,
        44.72261887065596
      ],
      [
        37.76554584503175,
        44.72749743052982
      ],
      [
        37.76176929473878,
        44.72920482928607
      ],
      [
        37.76292800903321,
        44.73139998220147
      ],
      [
        37.76528835296631,
        44.73301580541657
      ],
      [
        37.77996540069581,
        44.72569850946097
      ],
      [
        37.77872085571289,
        44.72426543060961
      ],
      [
        37.77700424194337,
        44.72389953244767
      ],
      [
        37.78090953826905,
        44.72155163084006
      ],
      [
        37.78485774993897,
        44.72271034743839
      ],
      [
        37.78670310974122,
        44.72161261650277
      ],
      [
        37.78438568115235,
        44.7202404235432
      ],
      [
        37.78653144836427,
        44.71816682576934
      ],
      [
        37.78623104095459,
        44.71526976327075
      ],
      [
        37.78537273406983,
        44.71212857373531
      ],
      [
        37.78687477111817,
        44.71035967352315
      ],
      [
        37.78846263885499,
        44.71026817721408
      ],
      [
        37.79035091400147,
        44.70956670070579
      ],
      [
        37.78975009918214,
        44.70401558678747
      ],
      [
        37.78949260711671,
        44.7026124737173
      ],
      [
        37.78932094573975,
        44.7018498980029
      ],
      [
        37.78940677642823,
        44.70120932664041
      ],
      [
        37.78910636901856,
        44.70084328267973
      ],
      [
        37.78957843780518,
        44.69989765840318
      ],
      [
        37.78962135314942,
        44.69882999952872
      ],
      [
        37.79000759124757,
        44.69770131016885
      ],
      [
        37.79052257537843,
        44.69718271578897
      ],
      [
        37.79090881347657,
        44.69658022530943
      ],
      [
        37.78729319572449,
        44.695779437121
      ],
      [
        37.78710007667542,
        44.6943151100804
      ],
      [
        37.77980446815491,
        44.69315582491296
      ],
      [
        37.7667260169983,
        44.69173719438422
      ],
      [
        37.76535272598267,
        44.69168380438475
      ],
      [
        37.76152253150941,
        44.69288125397425
      ],
      [
        37.75959134101868,
        44.69008970887077
      ],
      [
        37.75913000106812,
        44.68700817498383
      ]
    ]]
  },
  "properties": {
    "code": "center",
    "name": "Зона № Центральный",
    "address": "",
    "category": "Уличная парковка",
    "description": "",
    "objecttype": "zones",
    "linkpicture": null
  }
};

let GeoJsonData = {
  "type": "FeatureCollection",
  "features": [
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [[
          [
            37.76751995086671,
            44.66834766432113
          ],
          [
            37.76803493499757,
            44.66853078928521
          ],
          [
            37.76816368103028,
            44.67020940783032
          ],
          [
            37.76636123657227,
            44.6722542047393
          ],
          [
            37.76546001434326,
            44.67228472354974
          ],
          [
            37.7646017074585,
            44.6713386329532
          ],
          [
            37.76284217834473,
            44.67069772409691
          ],
          [
            37.7587652206421,
            44.67234576112239
          ],
          [
            37.75782108306886,
            44.67237627988462
          ],
          [
            37.75859355926514,
            44.6750008333139
          ],
          [
            37.75752067565919,
            44.6811345004573
          ],
          [
            37.75833606719971,
            44.68107347214158
          ],
          [
            37.75833606719971,
            44.68183632146762
          ],
          [
            37.76485919952393,
            44.68168375240597
          ],
          [
            37.76159763336182,
            44.68668780809289
          ],
          [
            37.75915145874024,
            44.68699291945778
          ],
          [
            37.75962352752686,
            44.69007445415632
          ],
          [
            37.76151180267335,
            44.69285074601181
          ],
          [
            37.76533126831055,
            44.69166092294134
          ],
          [
            37.76799201965333,
            44.69178295720174
          ],
          [
            37.77996540069581,
            44.69318633271469
          ],
          [
            37.7871322631836,
            44.6943151100804
          ],
          [
            37.78730392456055,
            44.695779437121
          ],
          [
            37.79090881347657,
            44.69657259880751
          ],
          [
            37.79202461242677,
            44.69471170231011
          ],
          [
            37.79798984527589,
            44.69214905844017
          ],
          [
            37.79640197753907,
            44.69053209384251
          ],
          [
            37.80344009399415,
            44.68507069102827
          ],
          [
            37.80661582946778,
            44.68122604281035
          ],
          [
            37.80901908874512,
            44.68043267094536
          ],
          [
            37.80944824218751,
            44.67860177133203
          ],
          [
            37.80884742736817,
            44.67768629982934
          ],
          [
            37.80447006225587,
            44.67768629982934
          ],
          [
            37.80000686645508,
            44.6756111775412
          ],
          [
            37.79949188232422,
            44.67469565878957
          ],
          [
            37.79966354370117,
            44.67384116157171
          ],
          [
            37.80060768127442,
            44.67262042940384
          ],
          [
            37.80086517333984,
            44.6722542047393
          ],
          [
            37.801251411438,
            44.67222368591278
          ],
          [
            37.8010368347168,
            44.67210161044606
          ],
          [
            37.80172348022462,
            44.67170486340331
          ],
          [
            37.80219554901124,
            44.6720710915392
          ],
          [
            37.80369758605958,
            44.67188797776053
          ],
          [
            37.80421257019044,
            44.67088084163618
          ],
          [
            37.8015947341919,
            44.6703009674437
          ],
          [
            37.80086517333984,
            44.66996524815421
          ],
          [
            37.80009269714356,
            44.66929380374146
          ],
          [
            37.79914855957032,
            44.66782880711428
          ],
          [
            37.79833316802979,
            44.66709629491535
          ],
          [
            37.79717445373536,
            44.66660794830666
          ],
          [
            37.79670238494874,
            44.66596698713919
          ],
          [
            37.79434204101563,
            44.66447138351995
          ],
          [
            37.79253959655762,
            44.66367778225687
          ],
          [
            37.79073715209962,
            44.66343359506718
          ],
          [
            37.79030799865723,
            44.66340307159614
          ],
          [
            37.78906345367432,
            44.66297574131428
          ],
          [
            37.78799057006837,
            44.6628231225931
          ],
          [
            37.78524398803712,
            44.6628231225931
          ],
          [
            37.78434276580811,
            44.66276207499211
          ],
          [
            37.78369903564454,
            44.66248735999223
          ],
          [
            37.78326988220216,
            44.6620905471387
          ],
          [
            37.7816390991211,
            44.6611748148032
          ],
          [
            37.78082370758057,
            44.66090009228211
          ],
          [
            37.77837753295898,
            44.66053379356238
          ],
          [
            37.77687549591065,
            44.65970961298158
          ],
          [
            37.77288436889649,
            44.65867173928834
          ],
          [
            37.77185440063477,
            44.65980118918023
          ],
          [
            37.77202606201173,
            44.66047274355076
          ],
          [
            37.77022361755372,
            44.66334202460587
          ],
          [
            37.76979446411134,
            44.66309783600193
          ],
          [
            37.76962280273438,
            44.66349464196102
          ],
          [
            37.769193649292,
            44.66428824573119
          ],
          [
            37.76885032653809,
            44.66462399790199
          ],
          [
            37.7686357498169,
            44.66537180256619
          ],
          [
            37.76803493499757,
            44.66651638285953
          ],
          [
            37.76812076568604,
            44.66663847009024
          ],
          [
            37.76794910430909,
            44.66737098807476
          ],
          [
            37.76773452758789,
            44.6679508915808
          ],
          [
            37.76773452758789,
            44.6679508915808
          ]
        ]]
      },
      "properties": {
        "code": "south",
        "name": "Зона № Южный район",
        "address": "",
        "category": "Уличная парковка",
        "description": "",
        "objecttype": "zones",
        "linkpicture": null
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [[
          [
            37.72039890289307,
            44.69921130852961
          ],
          [
            37.71567821502686,
            44.69951635392232
          ],
          [
            37.71505594253541,
            44.69959261501939
          ],
          [
            37.71505594253541,
            44.70142285121541
          ],
          [
            37.71550655364991,
            44.70258197088159
          ],
          [
            37.71503448486328,
            44.70547966851703
          ],
          [
            37.71353244781495,
            44.70557117239567
          ],
          [
            37.71288871765137,
            44.70639470079463
          ],
          [
            37.71409034729005,
            44.70791972244812
          ],
          [
            37.7131462097168,
            44.70917021022306
          ],
          [
            37.71383285522462,
            44.70996318847249
          ],
          [
            37.71477699279786,
            44.70950570234677
          ],
          [
            37.716064453125,
            44.71005468526371
          ],
          [
            37.71687984466553,
            44.70941420468772
          ],
          [
            37.71786689758302,
            44.7073707192814
          ],
          [
            37.71855354309083,
            44.70895671422275
          ],
          [
            37.72202968597413,
            44.71157961048961
          ],
          [
            37.72546291351319,
            44.71121363209965
          ],
          [
            37.72846698760986,
            44.71209807591383
          ],
          [
            37.72893905639649,
            44.7136229473009
          ],
          [
            37.7327585220337,
            44.71685554176403
          ],
          [
            37.73730754852296,
            44.71895968075193
          ],
          [
            37.73962497711182,
            44.71966104341111
          ],
          [
            37.7385950088501,
            44.72051486473866
          ],
          [
            37.74044036865235,
            44.72182606581594
          ],
          [
            37.74018287658692,
            44.72280182407619
          ],
          [
            37.73773670196534,
            44.72069782481243
          ],
          [
            37.73636341094971,
            44.7204233844848
          ],
          [
            37.73494720458985,
            44.7210942363208
          ],
          [
            37.73786544799805,
            44.72286280842103
          ],
          [
            37.737135887146,
            44.72322871314009
          ],
          [
            37.73619174957276,
            44.72277133187967
          ],
          [
            37.73473262786865,
            44.72274083966708
          ],
          [
            37.73400306701661,
            44.72249690138771
          ],
          [
            37.73310184478761,
            44.72264936293285
          ],
          [
            37.73241519927979,
            44.7231372371773
          ],
          [
            37.73159980773926,
            44.72322871314009
          ],
          [
            37.72941112518311,
            44.7229237927016
          ],
          [
            37.72653579711914,
            44.72319822116856
          ],
          [
            37.72460460662843,
            44.72429592201866
          ],
          [
            37.72670745849609,
            44.72435690478849
          ],
          [
            37.727952003479,
            44.72493623789581
          ],
          [
            37.72915363311768,
            44.72676567277721
          ],
          [
            37.7278232574463,
            44.72795477442435
          ],
          [
            37.72078514099121,
            44.73228411747805
          ],
          [
            37.70739555358887,
            44.7223444394408
          ],
          [
            37.69975662231446,
            44.72423493918451
          ],
          [
            37.69589424133302,
            44.72399100720512
          ],
          [
            37.69306182861329,
            44.72460083522505
          ],
          [
            37.67546653747559,
            44.73697895420884
          ],
          [
            37.67812728881837,
            44.73953961359393
          ],
          [
            37.681303024292,
            44.7406979698717
          ],
          [
            37.68078804016114,
            44.74411193758502
          ],
          [
            37.6732349395752,
            44.75252406891944
          ],
          [
            37.6662826538086,
            44.75916756813842
          ],
          [
            37.6908302307129,
            44.77586436732678
          ],
          [
            37.6966667175293,
            44.77123362204492
          ],
          [
            37.70130157470704,
            44.76818687659432
          ],
          [
            37.70885467529298,
            44.76721188410699
          ],
          [
            37.71142959594727,
            44.76964933446794
          ],
          [
            37.7021598815918,
            44.77586436732678
          ],
          [
            37.70679473876954,
            44.77891070777316
          ],
          [
            37.70971298217774,
            44.77708292279128
          ],
          [
            37.71400451660157,
            44.77537693794092
          ],
          [
            37.71966934204102,
            44.77135548852007
          ],
          [
            37.72172927856446,
            44.76903998152066
          ],
          [
            37.72104263305665,
            44.76355551570674
          ],
          [
            37.71966934204102,
            44.76258044504729
          ],
          [
            37.72224426269532,
            44.76221478930713
          ],
          [
            37.72619247436523,
            44.76130063983164
          ],
          [
            37.72953987121583,
            44.76075214320344
          ],
          [
            37.73391723632813,
            44.75880189079811
          ],
          [
            37.73443222045899,
            44.75794863467065
          ],
          [
            37.73357391357423,
            44.75484023793785
          ],
          [
            37.73709297180177,
            44.75191453532806
          ],
          [
            37.73846626281739,
            44.75051258367135
          ],
          [
            37.73666381835938,
            44.74819624120938
          ],
          [
            37.73657798767091,
            44.74728186994707
          ],
          [
            37.76275634765626,
            44.73155242028241
          ],
          [
            37.76290655136109,
            44.73139998220147
          ],
          [
            37.76174783706666,
            44.72920482928607
          ],
          [
            37.76556730270386,
            44.72751267538458
          ],
          [
            37.76185512542725,
            44.72264936293285
          ],
          [
            37.76297092437745,
            44.72197852912891
          ],
          [
            37.76041746139527,
            44.71968391378947
          ],
          [
            37.7579391002655,
            44.71863949062445
          ],
          [
            37.75646924972535,
            44.71706900862709
          ],
          [
            37.75580406188966,
            44.71641335789971
          ],
          [
            37.75549292564393,
            44.71608552975017
          ],
          [
            37.75505304336549,
            44.71570433189208
          ],
          [
            37.75477409362794,
            44.71539937179757
          ],
          [
            37.75396943092347,
            44.71467508513208
          ],
          [
            37.75337934494019,
            44.71452260257368
          ],
          [
            37.7530574798584,
            44.71430912631693
          ],
          [
            37.75288045406342,
            44.71425194504303
          ],
          [
            37.75246739387513,
            44.71416807907242
          ],
          [
            37.75173783302308,
            44.71394316518754
          ],
          [
            37.75071859359742,
            44.71363057155689
          ],
          [
            37.74955451488496,
            44.71318455089224
          ],
          [
            37.74846553802491,
            44.71279952174202
          ],
          [
            37.74619102478028,
            44.71159485953896
          ],
          [
            37.74325132369996,
            44.70999368741897
          ],
          [
            37.74123430252076,
            44.70996318847249
          ],
          [
            37.73554801940919,
            44.70929220758396
          ],
          [
            37.73271560668946,
            44.70729446842973
          ],
          [
            37.72707223892212,
            44.70572367853865
          ],
          [
            37.72049546241761,
            44.69922656083742
          ]
        ]]
      },
      "properties": {
        "code": "seaside",
        "name": "Зона № Приморский район",
        "address": "",
        "category": "Уличная парковка",
        "description": "",
        "objecttype": "zones",
        "linkpicture": null
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [[
          [
            37.77992248535156,
            44.7256375281054
          ],
          [
            37.78069496154786,
            44.72627782913262
          ],
          [
            37.78189659118653,
            44.72725351230753
          ],
          [
            37.78563022613526,
            44.72822917902534
          ],
          [
            37.78738975524903,
            44.72920482928607
          ],
          [
            37.78957843780518,
            44.72844260392613
          ],
          [
            37.79241085052491,
            44.72944873927983
          ],
          [
            37.79614448547364,
            44.72899140719779
          ],
          [
            37.80082225799561,
            44.72877798432199
          ],
          [
            37.80279636383057,
            44.72819868968954
          ],
          [
            37.80532836914063,
            44.72627782913262
          ],
          [
            37.80923366546632,
            44.72115522246563
          ],
          [
            37.81339645385743,
            44.71978301865789
          ],
          [
            37.81871795654298,
            44.72231394700319
          ],
          [
            37.82373905181885,
            44.72118571551391
          ],
          [
            37.82931804656982,
            44.71871572652981
          ],
          [
            37.83176422119141,
            44.71719098933812
          ],
          [
            37.83322334289552,
            44.71584918737395
          ],
          [
            37.83777236938477,
            44.71365344431882
          ],
          [
            37.84322261810303,
            44.71118313379603
          ],
          [
            37.84343719482422,
            44.70974969539716
          ],
          [
            37.84399509429932,
            44.70913971084265
          ],
          [
            37.84485340118408,
            44.70895671422275
          ],
          [
            37.84489631652833,
            44.70642520162145
          ],
          [
            37.84772872924805,
            44.70523565746684
          ],
          [
            37.84905910491944,
            44.70538816449373
          ],
          [
            37.85060405731201,
            44.70468662884321
          ],
          [
            37.85120487213136,
            44.70346654659258
          ],
          [
            37.85300731658936,
            44.70215492949401
          ],
          [
            37.8533935546875,
            44.70127033374219
          ],
          [
            37.85541057586671,
            44.70029421239948
          ],
          [
            37.85691261291505,
            44.69925706544099
          ],
          [
            37.857985496521,
            44.69864697031633
          ],
          [
            37.85850048065186,
            44.69672512865554
          ],
          [
            37.86103248596192,
            44.69535234555911
          ],
          [
            37.86154747009278,
            44.69437612444637
          ],
          [
            37.86403656005859,
            44.69358293267462
          ],
          [
            37.86468029022218,
            44.69315582491296
          ],
          [
            37.86571025848389,
            44.69123380100492
          ],
          [
            37.86935806274415,
            44.69321684050034
          ],
          [
            37.8700876235962,
            44.69642006855768
          ],
          [
            37.86948680877686,
            44.69773181557595
          ],
          [
            37.86824226379395,
            44.69809787920781
          ],
          [
            37.86734104156494,
            44.69760979385106
          ],
          [
            37.86682605743408,
            44.69678614048226
          ],
          [
            37.86643981933595,
            44.69605399431886
          ],
          [
            37.86493778228761,
            44.6953828522037
          ],
          [
            37.86360740661622,
            44.69519981209504
          ],
          [
            37.8634786605835,
            44.69541335883224
          ],
          [
            37.86382198333741,
            44.69654209278969
          ],
          [
            37.86450862884522,
            44.69800636351681
          ],
          [
            37.8651523590088,
            44.69879949470017
          ],
          [
            37.86523818969727,
            44.69928757002845
          ],
          [
            37.86485195159913,
            44.69962311943009
          ],
          [
            37.86416530609132,
            44.69962311943009
          ],
          [
            37.86339282989503,
            44.69901302816255
          ],
          [
            37.86253452301026,
            44.69855545549317
          ],
          [
            37.86176204681397,
            44.69821989990413
          ],
          [
            37.86038875579835,
            44.69806737399355
          ],
          [
            37.86060333251954,
            44.69886050434121
          ],
          [
            37.86081790924073,
            44.69940958821768
          ],
          [
            37.86210536956787,
            44.70081277891188
          ],
          [
            37.8625774383545,
            44.70148385809218
          ],
          [
            37.86287784576417,
            44.70249046227799
          ],
          [
            37.86270618438721,
            44.70294800384954
          ],
          [
            37.86193370819092,
            44.70307001432457
          ],
          [
            37.86150455474854,
            44.70294800384954
          ],
          [
            37.86107540130615,
            44.70270398212803
          ],
          [
            37.86021709442139,
            44.70242945646191
          ],
          [
            37.85991668701173,
            44.70200241394934
          ],
          [
            37.8591012954712,
            44.70172788495647
          ],
          [
            37.85755634307862,
            44.70151436150648
          ],
          [
            37.85609722137452,
            44.7016058716529
          ],
          [
            37.8559684753418,
            44.70197191079222
          ],
          [
            37.85622596740723,
            44.70300900911919
          ],
          [
            37.85717010498048,
            44.70438161069124
          ],
          [
            37.857985496521,
            44.70505264850421
          ],
          [
            37.85837173461915,
            44.70615069360154
          ],
          [
            37.85789966583253,
            44.70660820624484
          ],
          [
            37.85717010498048,
            44.70660820624484
          ],
          [
            37.85631179809571,
            44.70624219641947
          ],
          [
            37.85541057586671,
            44.7053576631205
          ],
          [
            37.85455226898194,
            44.70493064220767
          ],
          [
            37.85330772399903,
            44.70447311630558
          ],
          [
            37.85270690917969,
            44.70425960298046
          ],
          [
            37.8521490097046,
            44.70444261445022
          ],
          [
            37.85232067108155,
            44.7053576631205
          ],
          [
            37.85219192504884,
            44.70618119455693
          ],
          [
            37.85180568695069,
            44.70648620322687
          ],
          [
            37.85099029541016,
            44.70645570243219
          ],
          [
            37.84983158111573,
            44.70615069360154
          ],
          [
            37.84918785095216,
            44.70703521478281
          ],
          [
            37.84815788269043,
            44.70773672197112
          ],
          [
            37.84712791442872,
            44.70846872040779
          ],
          [
            37.85974502563477,
            44.71758742487312
          ],
          [
            37.86026000976563,
            44.72039289103473
          ],
          [
            37.86043167114259,
            44.72295428481777
          ],
          [
            37.85940170288087,
            44.7231372371773
          ],
          [
            37.85725593566895,
            44.72240542426776
          ],
          [
            37.85579681396485,
            44.72063683818546
          ],
          [
            37.85081863403321,
            44.7196000556913
          ],
          [
            37.84953117370606,
            44.718075341803
          ],
          [
            37.84575462341309,
            44.71929511612791
          ],
          [
            37.84275054931641,
            44.72277133187967
          ],
          [
            37.84575462341309,
            44.72557654668552
          ],
          [
            37.8472137451172,
            44.72832064693639
          ],
          [
            37.84910202026368,
            44.73027192791504
          ],
          [
            37.85150527954102,
            44.73490595641142
          ],
          [
            37.8417205810547,
            44.74100279661397
          ],
          [
            37.83639907836915,
            44.74136858658327
          ],
          [
            37.83433914184571,
            44.74600172587976
          ],
          [
            37.82506942749024,
            44.74795241028706
          ],
          [
            37.82043457031251,
            44.75343835725288
          ],
          [
            37.8087615966797,
            44.75636398271945
          ],
          [
            37.80395507812501,
            44.75685157256379
          ],
          [
            37.8010368347168,
            44.75880189079811
          ],
          [
            37.79245376586915,
            44.76014269639824
          ],
          [
            37.79159545898438,
            44.762336674811
          ],
          [
            37.7867889404297,
            44.76477433088797
          ],
          [
            37.78215408325196,
            44.77086802107666
          ],
          [
            37.7703094482422,
            44.77172108640259
          ],
          [
            37.76773452758789,
            44.76782125634016
          ],
          [
            37.7648162841797,
            44.76026458627357
          ],
          [
            37.76241302490235,
            44.7597770252294
          ],
          [
            37.75640487670899,
            44.7609959201255
          ],
          [
            37.75297164916993,
            44.76684625768132
          ],
          [
            37.74936676025391,
            44.76623687516234
          ],
          [
            37.73447513580323,
            44.75788768732225
          ],
          [
            37.73370265960693,
            44.75474881197326
          ],
          [
            37.73730754852296,
            44.75179262783834
          ],
          [
            37.73850917816163,
            44.75054306124288
          ],
          [
            37.73670673370362,
            44.74819624120938
          ],
          [
            37.73657798767091,
            44.74734282848123
          ],
          [
            37.76282072067261,
            44.7315676640684
          ],
          [
            37.76292800903321,
            44.73139998220147
          ],
          [
            37.76528835296631,
            44.73304629221313
          ],
          [
            37.77966499328614,
            44.72585096256864
          ],
          [
            37.77966499328614,
            44.72585096256864
          ]
        ]]
      },
      "properties": {
        "code": "east",
        "name": "Зона № Восточный район",
        "address": "",
        "category": "Уличная парковка",
        "description": "",
        "objecttype": "zones",
        "linkpicture": null
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [[
          [
            37.75902271270753,
            44.68702343050587
          ],
          [
            37.75507450103761,
            44.68717598550529
          ],
          [
            37.75554656982423,
            44.68845743163819
          ],
          [
            37.75172710418702,
            44.69126430981911
          ],
          [
            37.75443077087403,
            44.69208804172781
          ],
          [
            37.75670528411866,
            44.68946426228315
          ],
          [
            37.75855064392091,
            44.69196600811024
          ],
          [
            37.75726318359375,
            44.69245414103768
          ],
          [
            37.75623321533204,
            44.6929422698509
          ],
          [
            37.75610446929932,
            44.69318633271469
          ],
          [
            37.75691986083985,
            44.69425409565017
          ],
          [
            37.75498867034912,
            44.69498626457038
          ],
          [
            37.75477409362794,
            44.69526082552888
          ],
          [
            37.75520324707032,
            44.69590146270299
          ],
          [
            37.75455951690675,
            44.69605399431886
          ],
          [
            37.75447368621826,
            44.69626753790609
          ],
          [
            37.75524616241456,
            44.69776232096699
          ],
          [
            37.75378704071046,
            44.6981588895881
          ],
          [
            37.75052547454835,
            44.69745726633335
          ],
          [
            37.74533271789552,
            44.69852495052
          ],
          [
            37.7446460723877,
            44.69889100913762
          ],
          [
            37.74142742156983,
            44.69956211059261
          ],
          [
            37.74065494537354,
            44.69956211059261
          ],
          [
            37.74065494537354,
            44.69779282634199
          ],
          [
            37.73868083953858,
            44.69849444553072
          ],
          [
            37.73670673370362,
            44.69599298172071
          ],
          [
            37.73524761199952,
            44.69623703172753
          ],
          [
            37.73387432098389,
            44.69663361079493
          ],
          [
            37.73305892944337,
            44.69709119865158
          ],
          [
            37.73258686065675,
            44.69904353287858
          ],
          [
            37.73310184478761,
            44.69910454226248
          ],
          [
            37.73387432098389,
            44.69977564124254
          ],
          [
            37.73310184478761,
            44.70041622846732
          ],
          [
            37.73245811462403,
            44.70001967530671
          ],
          [
            37.73262977600098,
            44.70169738165467
          ],
          [
            37.72052764892579,
            44.69922656083742
          ],
          [
            37.72709369659425,
            44.70572367853865
          ],
          [
            37.73271560668946,
            44.70727921824734
          ],
          [
            37.73554801940919,
            44.70926170826785
          ],
          [
            37.74134159088136,
            44.70996318847249
          ],
          [
            37.74322986602784,
            44.70999368741897
          ],
          [
            37.74837970733643,
            44.71273852679008
          ],
          [
            37.75095462799073,
            44.71371443830646
          ],
          [
            37.75262832641602,
            44.71420238789327
          ],
          [
            37.75297164916993,
            44.71426338130233
          ],
          [
            37.75340080261231,
            44.71450735429573
          ],
          [
            37.75395870208741,
            44.71465983689431
          ],
          [
            37.75554656982423,
            44.71609315368172
          ],
          [
            37.75722026824952,
            44.71789237343644
          ],
          [
            37.75799274444581,
            44.71865473781356
          ],
          [
            37.76035308837891,
            44.71966104341111
          ],
          [
            37.76155471801759,
            44.72054535812446
          ],
          [
            37.76301383972168,
            44.72197852912891
          ],
          [
            37.76189804077149,
            44.72261887065596
          ],
          [
            37.76554584503175,
            44.72749743052982
          ],
          [
            37.76176929473878,
            44.72920482928607
          ],
          [
            37.76292800903321,
            44.73139998220147
          ],
          [
            37.76528835296631,
            44.73301580541657
          ],
          [
            37.77996540069581,
            44.72569850946097
          ],
          [
            37.77872085571289,
            44.72426543060961
          ],
          [
            37.77700424194337,
            44.72389953244767
          ],
          [
            37.78090953826905,
            44.72155163084006
          ],
          [
            37.78485774993897,
            44.72271034743839
          ],
          [
            37.78670310974122,
            44.72161261650277
          ],
          [
            37.78438568115235,
            44.7202404235432
          ],
          [
            37.78653144836427,
            44.71816682576934
          ],
          [
            37.78623104095459,
            44.71526976327075
          ],
          [
            37.78537273406983,
            44.71212857373531
          ],
          [
            37.78687477111817,
            44.71035967352315
          ],
          [
            37.78846263885499,
            44.71026817721408
          ],
          [
            37.79035091400147,
            44.70956670070579
          ],
          [
            37.78975009918214,
            44.70401558678747
          ],
          [
            37.78949260711671,
            44.7026124737173
          ],
          [
            37.78932094573975,
            44.7018498980029
          ],
          [
            37.78940677642823,
            44.70120932664041
          ],
          [
            37.78910636901856,
            44.70084328267973
          ],
          [
            37.78957843780518,
            44.69989765840318
          ],
          [
            37.78962135314942,
            44.69882999952872
          ],
          [
            37.79000759124757,
            44.69770131016885
          ],
          [
            37.79052257537843,
            44.69718271578897
          ],
          [
            37.79090881347657,
            44.69658022530943
          ],
          [
            37.78729319572449,
            44.695779437121
          ],
          [
            37.78710007667542,
            44.6943151100804
          ],
          [
            37.77980446815491,
            44.69315582491296
          ],
          [
            37.7667260169983,
            44.69173719438422
          ],
          [
            37.76535272598267,
            44.69168380438475
          ],
          [
            37.76152253150941,
            44.69288125397425
          ],
          [
            37.75959134101868,
            44.69008970887077
          ],
          [
            37.75913000106812,
            44.68700817498383
          ]
        ]]
      },
      "properties": {
        "code": "center",
        "name": "Зона № Центральный",
        "address": "",
        "category": "Уличная парковка",
        "description": "",
        "objecttype": "zones",
        "linkpicture": null
      }
    }
  ]
};

GeoJsonData.features.forEach(function(item){
  // console.log(item.properties.code);
  switch (item.properties.code){
    case 'south':
      console.log('south');
      break;
    case 'seaside':
      console.log('seaside');
      break;
    case 'east':
      console.log('east');
      break;
    case 'center':
      console.log('center');
      break;
  }
});


//eslint-disable-next-line
import LeafletSearch from 'leaflet-search';
import L from 'leaflet';
import 'leaflet.markercluster/dist/leaflet.markercluster';
import 'leaflet.gridlayer.googlemutant';

import PARK_TYPES from '../../constants/parkTypes';
import OBJECT_TYPES from '../../constants/objectTypes';
import styles from './assets/styles';
import iconUrls from './assets/urls';
import banner from '../../Assets/Images/banner.jpg';

import './MainMap.css';
import CityStore from '../../Stores/CityStore';

import CurrentPositionButton from '../Common/Buttons/CurrentPositionButton';

import {
  findAllParkings,
  findNearestToPoint,
  findOnly,
  findMarkerByLatLng,
} from './helpers';
import { BANNER, BANNER_URL, FOCUS_ON_MARKER } from '../../Config';
// import ParkingTypeToggle from '../ParkingSection/ParkingTypeToggle';
import ObjectTypes from "../../constants/objectTypes";

@translate(['map'], { wait: true })
@observer
class MainMap extends Component {
  @observable polyLines = [];
  @observable UIStore;
  @observable searchCreated = false;

  currentObject = {
    lineColor: null,
    defaultIcon: null,
    objectId: null,
  };

  map = null;

  markerCluster = null;

  controlSearch = null;

  objectId = 1;

  constructor(props) {
    super(props);
    this.state = {
      position: [44.58883, 33.5224],
      zoom: OptionsStore.initialZoom,
      dataForInfo: null,
      height: 0,
      marker: null,
      hasError: false,
      currentPos: null,
      currentAccuracy: null,
    };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!UIStore.reservationVisibility) {
      this.resetMarkerIcon();
    }
  }

  componentDidMount() {
    this.initMapStyle();
    this.initEvents();
    this.addMap();
    this.createSearch();
    this.map.on('locationfound', this.onCurrentLocationFound);
    this.map.on('locationerror', this.onCurrentLocationError);
  }

  componentDidUpdate() {
    if (this.map) {
      if (this.markerCluster) {
        this.map.removeLayer(this.markerCluster);
        this.polyLines = [];
      }

      this.createMapElements();
      if (!this.searchCreated) this.createSearch();
    }
  }

  componentWillUnmount() {
    this.removeEvents();
  }

  createSearch = () => {
    const { t, data } = this.props;
    if (data.hasOwnProperty('features'))
      if (this.markerCluster !== null) {
        let controlSearch = new L.Control.Search({
          propertyName: 'title',
          container: 'find-box',
          collapsed: false,
          layer: this.markerCluster,
          buildTip: this.customTip,
          initial: false,
          zoom: 18,
          marker: false,
          textPlaceholder: t('map:searchField'),
          filterData: function(text, records) {
            let I, iCase, regSearch, fRecords = {};
            text = text.replace(/[.*+?^${}()|[\]\\]/g, '');  //sanitize remove all special characters
            if(text==='') return [];
            text = text.replace(/[\s,]/g, '.*')
            I = this.options.initial ? '^' : '';  //search only initial text
            iCase = !this.options.casesensitive ? 'i' : undefined;
            regSearch = new RegExp(I + text, iCase);
            //TODO use .filter or .map
            for(let key in records) {
              if( regSearch.test(key) )
                fRecords[key]= records[key];
            }
            return fRecords;
          }
        }).on('search:locationfound', (e) => {
          this.locationFoundEvent(e);
        });

        this.controlSearch = controlSearch;
        this.map.addControl(this.controlSearch);
        this.searchCreated = true;
      }
  };

  customTip(text, val) {
    const iconUrl = val.layer.options.icon.options.iconUrl;
    return `<li class='search-tip'><span class="search-tip__text">${text}</span><i class="search-tip__icon" style="background-image: url('${iconUrl}');"/></li>`;
  }

  renderAdvert() {
    if (BANNER) {
      return (
        <a className="map_banner" href={BANNER_URL} target="_blank">
          <img src={banner} alt="Мир кэшбек" />
        </a>
      );
    }

    return null;
  }

  render() {
    if (!this.state.hasError)
      return (
        <Row
          noGutters
          style={{ height: `calc(100% - ${UIStore.footerHeight}px)` }}
        >
          <Col>
            <div
              ref={(mapContainer) => (this.mapContainer = mapContainer)}
              id={'map'}
              style={{ height: '100%' }}
            />
            {this.renderAdvert()}
            <CurrentPositionButton onClick={this.handlePosition} />
            <div id={'find-box'} />
          </Col>
        </Row>
      );

    return <div>Извините, но возникла ошибка, попробуйте позже</div>;
  }

  setView = (e) => {
    this.map.setView(e, 18);
  };

  onCurrentLocationFound = (e) => {
    const { t, data } = this.props;
    const { currentPos } = this.state;

    if (this.bounds.contains(e.latlng)) {
      if (currentPos) {
        this.map.removeLayer(currentPos);
      }

      const positionMarker = L.marker(e.latlng).addTo(this.map);

      if (FOCUS_ON_MARKER === 1) {
        const parkings = findOnly('parkings', data.features);
        const nearestMarkerLatLng = findNearestToPoint(
          positionMarker,
          parkings
        );

        this.setView(nearestMarkerLatLng);
        const nearestMarker = findMarkerByLatLng(
          nearestMarkerLatLng,
          findAllParkings(this.markerCluster)
        );

        if (nearestMarker) {
          this.clickOnParkingMarker(nearestMarker);
          this.clickOnObject(nearestMarker.options.feature.properties);
        } else {
          alert(t('errors:CantFindParking'));
        }
      } else {
        this.setView(positionMarker);
      }
    } else {
      alert(t('errors:NotInCity'));
      this.map.setView(this.currentCoords, this.currentZoom);
    }
  };

  onCurrentLocationError = () => {
    const { t } = this.props;
    window.alert(t('errors:CantFindLocation'));
  };

  handlePosition = () => {
    this.currentCoords = L.latLng(this.map.getCenter());
    this.currentZoom = this.map.getZoom();
    this.map.locate({ setView: false });
  };

  addMap = () => {
    const initCenter = this.initMapCenter();
    const mapCenter = initCenter
      ? [initCenter[0], initCenter[1]]
      : this.state.position;

    const southWest = L.latLng(CityStore.mapBounds.southWest.slice());
    const northEast = L.latLng(CityStore.mapBounds.northEast.slice());

    const bounds = L.latLngBounds(northEast, southWest);

    this.bounds = bounds;

    this.map = L.map('map', {
      // maxBounds: bounds,
      maxBoundsViscosity: 1.0,
      zoomControl: true,
      maxZoom: 19,
      minZoom: 13,
      zoom: OptionsStore.initialZoom,
      preferCanvas: true,
    }).setView(mapCenter, this.state.zoom);

    this.map.zoomControl.setPosition('bottomright');
    // this.map.createPane('states');
    // this.map.getPane('states').style.pointerEvents = 'none';
    // var fs = require('fs');
    // let GeoJsonData = fs.readFileSync('/part.json', 'utf8');
    // var geoJsonOptions = new Object();
    // var geojson = L.geoJson(GeoJsonData).addTo(this.map);
    var southJson = L.geoJson(south, {color: 'rgb(123, 87, 161)'}).addTo(this.map);
    var eastJson = L.geoJson(east, {color: 'rgb(159, 70, 76)'}).addTo(this.map);
    var seasideJson = L.geoJson(seaside, {color: 'rgb(1, 164, 113)'}).addTo(this.map);
    var centerJson = L.geoJson(center, {color: 'rgb(246, 164, 77)'}).addTo(this.map);
    const osmUrl = CityStore.mapSource;
    const osmAttrib =
      'Map data © <a href="https://openstreetmap.org">OpenStreetMap</a> contributors';

    if (osmUrl === 'google') {
      L.gridLayer
        .googleMutant({
          type: 'roadmap',
        })
        .addTo(this.map);
    } else {
      // eslint-disable-next-line
      const osm = new L.TileLayer(osmUrl, {
        maxZoom: 22,
        maxNativeZoom: 18,
        attribution: osmAttrib,
      }).addTo(this.map);
    }
    this.createMarkerClusterGroup();
    this.map.invalidateSize();
  };

  createMarkerClusterGroup = () => {
    this.markerCluster = L.markerClusterGroup({
      maxClusterRadius: 120,
      disableClusteringAtZoom: 17,
      spiderfyOnMaxZoom: false,
      spiderLegPolylineOptions: {
        color: '#111',
        fill: '#111',
        fillColor: '#111',
      },
      iconCreateFunction: function(cluster) {
        return L.divIcon({
          html: '<b>' + cluster.getChildCount() + '</b>',
          className: 'cluster',
          iconSize: L.point(40, 40),
        });
      },
    });
  };

  createMapElements = () => {
    const data = this.props.data;
    const status = mapStore.status;
    const map = this.map;

    this.createMarkerClusterGroup();
    map.addLayer(this.markerCluster);

    if (status === 'done' && data.features) {
      data.features.forEach((item) => {
        let stylesAndIcons = this.getStylesAndIcon(item);
        if (item) {
          if (
            item.geometry.type === 'LineString' ||
            item.geometry.type === 'Polygon'
          ) {
            const isPolyline = item.geometry.type === 'LineString';

            const geometry = isPolyline
              ? this.addPolyLine(item)
              : this.addPolygon(item);

            // если резидентная зона, не добавляем маркер
            if (item.properties.objecttype !== ObjectTypes.RESIDENTZONE) {
              this.addParkingPoint(geometry, item);
            }
          } else {
            this.addParcomatPoint(item, stylesAndIcons);
          }
        }
        return null;
      });
    }

    if (this.controlSearch) {
      const { t } = this.props;

      const input = document.querySelector(
        '.leaflet-control-search > input.search-input'
      );
      input.placeholder = t('map:searchField');
      this.controlSearch.setLayer(this.markerCluster);
    }
  };

  addParcomatPoint = (item, stylesAndIcons) => {
    const category = this.getCategory(item.properties.category);
    let address =
      item.properties.address !== '' ? item.properties.address : ' ';

    if (item.geometry && item.geometry.coordinates) {
      let marker = L.marker(new L.latLng(item.geometry.coordinates.toJS()), {
        icon: new L.Icon({
          iconSize: this.getIconSize(category),
          iconAnchor: this.getIconAnchor(category),
          popupAnchor: [1, -24],
          iconUrl: stylesAndIcons.icon,
        }),
        title: address + ' | ' + category,
        objectId: this.objectId,
        type: 'Point',
        feature: item,
      }).on('click', () => {
        this.clickOnParcomatPoint(marker);
        this.clickOnObject(item.properties);
      });
      this.markerCluster.addLayer(marker);
      this.objectId++;
    }
  };

  addPolyLine = (feature) => {
    let coordinates = this.toArray(feature.geometry.coordinates);
    const category = feature.properties.category || feature.properties.objecttype;
    let style = this.getStylesAndIcon(feature).style;
    let polyLine = L.polyline(coordinates, {
      color: style.color,
      title: ' ',
      objectId: this.objectId,
      type: 'PolyLine',
      weight: style.weight,
      feature: feature,
    })
      .on('click', () => {
        this.clickOnParkingLine(
          polyLine,
          this.getStylesAndIcon(feature).icon,
          category
        );
        this.clickOnObject(feature.properties);
      })
      .addTo(this.markerCluster).bringToBack();
    this.objectId++;

    return polyLine;
  };

  addPolygon = (feature) => {
    let coordinates = this.toArray(feature.geometry.coordinates);
    const category = feature.properties.category || feature.properties.objecttype;
    let style = this.getStylesAndIcon(feature).style;
    let polygone = L.polygon(coordinates, {
      color: style.color,
      title: ' ',
      type: 'Polygon',
      objectId: this.objectId,
      weight: style.weight,
      feature: feature,
    })
      .on('click', () => {
        this.clickOnParkingLine(
          polygone,
          this.getStylesAndIcon(feature).icon,
          category
        );
        this.clickOnObject(feature.properties);
      })
      .addTo(this.markerCluster).bringToBack();
    this.objectId++;

    return polygone;
  };

  addParkingPoint = (polyLine, feature) => {
    let centerCoordinates = polyLine.getCenter();
    let iconUrl = this.getStylesAndIcon(feature).icon;
    const category = feature.properties.category;

    let smallIcon = new L.Icon({
      iconSize: this.getIconSize(category),
      iconAnchor: this.getIconAnchor(category),
      popupAnchor: [1, -24],
      iconUrl: iconUrl,
    });

    let address =
      feature.properties.address && feature.properties.address !== ''
        ? feature.properties.address
        : ' ';
    let zonename =
      feature.properties.zone &&
      feature.properties.zone.zonename &&
      feature.properties.zone.zonename !== ''
        ? feature.properties.zone.zonename
        : ' ';
    let code = feature.properties.code;

    let marker = L.marker(centerCoordinates, {
      icon: smallIcon,
      title: address + ' | ' + zonename + ' | ' + code + ' | ' + category,
      objectId: polyLine.options.objectId,
      type: 'Point',
      feature: feature,
    })
      .on('click', () => {
        this.clickOnParkingMarker(marker);
        this.clickOnObject(feature.properties);
      })
      .addTo(this.markerCluster);
  };

  clickOnParcomatPoint = (marker) => {
    const feature = marker.options.feature;
    const isTerminal =
      feature.properties.objecttype === OBJECT_TYPES.TERMINALS ? true : false;
    const isInformationTable = feature.properties.objecttype === OBJECT_TYPES.INFORMATIONTABLE;
    const isChurch = feature.properties.objecttype === OBJECT_TYPES.CHURCH;
    const isMuseum = feature.properties.objecttype === OBJECT_TYPES.MUSEUM;
    const isSight = feature.properties.objecttype === OBJECT_TYPES.SIGHT;
    let icon;
    if (isTerminal) {
      icon = iconUrls.terminal.default;
    } else if(isInformationTable) {
      icon = iconUrls.informationtable.default;
    } else if(isChurch) {
      icon = iconUrls.church.default;
    } else if(isMuseum) {
      icon = iconUrls.museum.default;
    } else if(isSight) {
      icon = iconUrls.sight.default;
    } else {
      icon = iconUrls.parkomat.default;
    }

    this.map.setView(marker.getLatLng(), 20);
    this.resetMarkerIcon();
    this.setIconToMarker(marker, icon, 'big');
    this.currentObject.defaultIconUrl = this.getStylesAndIcon(feature).icon;
    this.currentObject.objectId = marker.options.objectId;
  };

  clickOnParkingLine = (polyLine, iconUrl, category) => {
    this.resetMarkerIcon();
    this.currentObject.lineColor = polyLine.options.color;

    if (category === OBJECT_TYPES.RESIDENTZONE) {
      polyLine.setStyle({
        color: styles.residentzone.colorActive,
      });
    } else {
      polyLine.setStyle({
        color: '#456cab',
      });
    }

    this.markerCluster.eachLayer((layer) => {
      if (
        layer.options.objectId &&
        layer.options.objectId === polyLine.options.objectId
      ) {
        if (layer.options.type === 'Point') {
          const category = this.getCategory(
            layer.options.feature.properties.category
          );
          this.setIconToMarker(layer, iconUrls.parking[category].orange, 'big');
        }
      }
    });

    this.currentObject.defaultIconUrl = iconUrl;
    this.currentObject.objectId = polyLine.options.objectId;
  };

  clickOnParkingMarker = (marker) => {
    this.map.setView(marker.getLatLng(), 20);
    this.resetMarkerIcon();

    this.markerCluster.eachLayer((layer) => {
      if (
        layer.options.objectId &&
        layer.options.objectId === marker.options.objectId
      ) {
        if (
          layer.options.type === 'PolyLine' ||
          layer.options.type === 'Polygon'
        ) {
          this.currentObject.lineColor = layer.options.color;
          this.currentObject.objectId = layer.options.objectId;
          // layer.setStyle({
          //   color: '#FF6D3D'
          // });
        }
      }
    });
    const category = this.getCategory(
      marker.options.feature.properties.category
    );
    this.setIconToMarker(marker, iconUrls.parking[category].default, 'big');
    this.currentObject.defaultIconUrl = this.getStylesAndIcon(
      marker.options.feature
    ).icon;
  };

  locationFoundEvent = (e) => {
    if (
      e.layer.options.feature.properties.objecttype === OBJECT_TYPES.PARKINGS
    ) {
      this.clickOnParkingMarker(e.layer);
    } else if (
      e.layer.options.feature.properties.objecttype === OBJECT_TYPES.PARKOMATS
    ) {
      this.clickOnParcomatPoint(e.layer);
    }
    this.clickOnObject(e.layer.options.feature.properties);
  };

  resetMarkerIcon = () => {
    this.markerCluster.eachLayer((layer) => {
      if (
        layer.options.objectId &&
        layer.options.objectId === this.currentObject.objectId
      ) {
        if (layer.options.type === 'Point') {
          this.setIconToMarker(
            layer,
            this.currentObject.defaultIconUrl,
            'normal'
          );
        } else if (
          layer.options.type === 'PolyLine' ||
          layer.options.type === 'Polygon'
        ) {
          layer.setStyle({
            color: this.currentObject.lineColor,
          });
        }
      }
    });
  };

  setIconToMarker = (layer, iconUrl, size) => {
    let iconSize = null;
    let iconAnchor = null;
    const category = this.getCategory(
      layer.options.feature.properties.category
    );

    if (size === 'big') {
      iconSize = this.getIconSize(category, size);
      iconAnchor = this.getIconAnchor(category, size);
    } else {
      iconSize = this.getIconSize(category);
      iconAnchor = this.getIconAnchor(category, size);
    }
    layer.setIcon(
      new L.Icon({
        iconSize: iconSize,
        iconAnchor: iconAnchor,
        popupAnchor: [1, -24],
        iconUrl: iconUrl,
      })
    );
  };

  clickOnObject = (properties) => {
    reservationStore.data = properties;
    //UIStore.reservationVisibility = true
    if (UIStore.filterVisibility) {
      UIStore.toggleVisibilityFilter();
    }
    UIStore.reservationVisibility = true;
  };

  toArray = (observableArray) => {
    let array = [];
    observableArray.forEach((item) => {
      array.push(item.toJS());
    });
    return array;
  };

  getCategory(category) {
    const isFree = category === PARK_TYPES.FREE;
    const isForDisabled = category === PARK_TYPES.FORDISABLED;
    const isChurch = category === PARK_TYPES.CHURCH;
    const isMuseum = category === PARK_TYPES.MUSEUM;
    const isSight = category === PARK_TYPES.SIGHT;
    const isResident = category === PARK_TYPES.RESIDENT;
    const isTaxi = category === PARK_TYPES.TAXI;
    const isBus = category === PARK_TYPES.BUS;

    return (isFree || isForDisabled || isChurch || isMuseum || isSight || isResident || isTaxi || isBus) ? category : PARK_TYPES.DEFAULT;
  }

  getIconAnchor(category, size) {
    switch (category) {
      case PARK_TYPES.FREE:
      case PARK_TYPES.FORDISABLED: {
        return size === 'big' ? [7, 70] : [13, 58];
      }
      default:
        return size === 'big' ? [13, 70] : [20, 55];
    }
  }

  getIconSize(category, size) {
    switch (category) {
      case PARK_TYPES.FREE:
      case PARK_TYPES.FORDISABLED:
      case PARK_TYPES.RESIDENT:
      case PARK_TYPES.TAXI:
      case PARK_TYPES.BUS: {
        return size === 'big' ? [60, 70] : [60, 60];
      }
      case PARK_TYPES.SIGHT: {
        return size === 'big' ? [60, 60] : [35, 35];
      }
      case PARK_TYPES.MUSEUM: {
        return size === 'big' ? [60, 60] : [40, 40];
      }
      case PARK_TYPES.CHURCH: {
          return size === 'big' ? [60, 60] : [50, 50];
      }
      default:
        return size === 'big' ? [70, 80] : [70, 70];
    }
  }

  getStylesAndIcon = (feature) => {
    const category = this.getCategory(feature.properties.category);
    const objType = feature.properties.objecttype;
    const isMuseum = objType === OBJECT_TYPES.MUSEUM;
    const isSight = objType === OBJECT_TYPES.SIGHT;
    const isChurch = objType === OBJECT_TYPES.CHURCH;

    let stylesAndIcon = {
      style: styles.default,
      icon: (isMuseum || isSight || isChurch)
        ? iconUrls[objType.toLowerCase()].default
        : iconUrls.parking[category].default,
    };

    if (objType === OBJECT_TYPES.PARKINGS) {
      if (feature.properties.inactive) {
        return {
          style: styles.inactive,
          icon: iconUrls.parking[category].black,
        };
      }

      if (category === PARK_TYPES.FREE) {
        stylesAndIcon = {
          icon: iconUrls.parking[PARK_TYPES.FREE].default,
          style: styles.parking,
        };
      } else {
        const spaces = feature.properties.spaces;
        if (spaces.free === -1) {
          stylesAndIcon = this.getColoredIcon(category, -1);
        } else {
          const takedPlaces = spaces.total - spaces.free;

          const ratio = !takedPlaces ? 0 : (takedPlaces / spaces.total) * 100;

          stylesAndIcon = this.getColoredIcon(category, ratio);
        }
      }
    } else if (objType === OBJECT_TYPES.INFORMATIONTABLE) {
      stylesAndIcon = {
        style: styles.light,
        icon: iconUrls.informationtable.default,
      };
    } else if (isChurch) {
      stylesAndIcon = {
        style: styles.light,
        icon: iconUrls.church.default,
      };
    } else if (isMuseum) {
      stylesAndIcon = {
        style: styles.light,
        icon: iconUrls.museum.default,
      };
    } else if (isSight) {
      stylesAndIcon = {
        style: styles.light,
        icon: iconUrls.sight.default,
      };
    } else if(objType === OBJECT_TYPES.RESIDENTZONE) {
      stylesAndIcon = {
        style: styles.residentzone,
        icon: null,
      };

    } else {
      const icon =
        objType === OBJECT_TYPES.TERMINALS
          ? iconUrls.terminal.default
          : iconUrls.parkomat.default;

      stylesAndIcon = {
        style: styles.light,
        icon,
      };
    }

    return stylesAndIcon;
  };

  getColoredIcon(category, ratio) {
    switch (true) {
      case ratio < 0:
        return {
          style: styles.default,
          icon: iconUrls.parking[category].default,
        };
      case ratio <= 50:
        return {
          style: styles.light,
          icon: iconUrls.parking[category].green,
        };

      case ratio > 50 && ratio <= 70:
        return {
          style: styles.medium,
          icon: iconUrls.parking[category].yellow,
        };

      case ratio > 70:
        return {
          style: styles.high,
          icon: iconUrls.parking[category].red,
        };

      default:
        break;
    }
  }

  initEvents = () => {
    window.addEventListener('load', this.onResize);
    window.addEventListener('resize', this.onResize);
  };

  removeEvents = () => {
    window.removeEventListener('resize', this.onResize);
    window.removeEventListener('load', this.onResize);
  };

  initMapStyle = () => {
    UIStore.computeMapStyle();
  };

  initMapCenter() {
    const isMapcenter =
      OptionsStore.hasOwnProperty('mapCenter') && OptionsStore.mapCenter;

    return isMapcenter
      ? [OptionsStore.mapCenter[0], OptionsStore.mapCenter[1]]
      : false;
  }

  onResize = () => {
    UIStore.computeMapStyle();
    if (this.map && this.map._map && this.map._map._container) {
      this.map.invalidateSize();
    }
  };
}

export default MainMap;
