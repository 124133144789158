import React from 'react';
import Link from '../../../../Containers/Links/LngLink';

import logoSvg from '../../../../logo.svg';
import logoNovoros from '../../../../NVRSK.png';

function HeaderLogo(props) {
  return (
    <Link
      to="/"
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <img className='logo-nvrsk' alt="logo" src={logoNovoros}  />
      <span className="logo-title">{props.children}</span>
    </Link>
  );
}

export default HeaderLogo;
